<template>
        <v-app-bar class="appBar">
            <v-toolbar-title @click="handleEmit(0)" class="appBar__logo">
                <img class="logo__small" src="../assets/drivbank_logo.png" alt="logo"/> 
                Helenas Drivbänk
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <div v-if="$vuetify.breakpoint.smAndDown">
                    <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                        >
                        <v-icon>
                            mdi-menu
                        </v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="handleEmit(1)">
                            <v-list-item-title>Samtal</v-list-item-title>
                        </v-list-item>

                        <v-list-item @click="handleEmit(3)">
                            <v-list-item-title>Om mig</v-list-item-title>
                        </v-list-item>

                        <v-list-item @click="handleEmit(4)">
                            <v-list-item-title>Priser</v-list-item-title>
                        </v-list-item>

                        <v-list-item @click="handleDialogEmit()">
                            <v-list-item-title>Boka/Kontakt</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>

            <div v-else>
                <v-tabs light optional background-color="#fffff4">
                    <v-tab @click="handleEmit(1)">Samtal</v-tab>

                    <v-tab @click="handleEmit(3)">Om mig</v-tab>

                    <v-tab @click="handleEmit(4)">Priser</v-tab>

                    <v-btn @click="handleDialogEmit()" elevation="0" plain style="height: 100%">Boka/Kontakt</v-btn>
                </v-tabs>
            </div>
        </v-app-bar>
</template>

<script>
export default {
    data: () => {
        return {
            dialog: false
        }
    },
    methods: {
        handleEmit(index) {
            this.$emit('tabIndexChange', index)
        },
        handleDialogEmit() {
            this.$emit('showDialog')
        }
    }
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap');
.appBar__logo {
    cursor: pointer;
    display: flex; 
    align-items: center;
    font-family: 'Courier Prime';
}
.logo__small {
    border-radius: 8rem;
    margin-right: 0.2rem;
    height: 3rem;
}
.appBar {
    background-color: transparent !important
}
</style>