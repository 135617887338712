import axios from 'axios'

const api = axios.create({timeout: 5000})

export async function sendEmail (name, email, message, phoneNumber) {
  const response = await api.post(`/api/postmaster`, {name, email, message, phoneNumber})
  if (!response) {
    throw new Error('could not send email')
  }
  return response
}