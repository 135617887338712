<template>
    <div class="componentWrapper">
        <div style="margin-bottom: 3rem; text-align: center">
            <h1 style="font-size: 3rem">Priser</h1>
        </div>
        <div class="tabSection">
            <div class="sectionBodyWrapper">
                <div class="threeGridColumnWidth">
                   <h2>Samtal</h2>
                    Du är välkommen på samtal i min lokal i Västra Göteborg.
                    <br/>
                    <br/>
                    Samtal erbjuds även digitalt via Zoom.
                    <br/>
                    <br/>
                    <h3>Kostnad</h3>
                    <b>Samtal (60min):</b> 600kr
                    <br/>
                    <br/>
                    <h3>Bokning</h3>
                    Bokning sker via <a href="mailto:helenasdrivbank@gmail.com">mail</a> eller via Boka/Kontakt.
                </div>
                <div class="threeGridColumnWidth">
                    <h2>Föreläsningar</h2>
                    <div>
                        Kontakta mig för ytterligare information och för kostnadsförslag
                    </div>
                </div>
                <div class="threeGridColumnWidth">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name : '',
        components : {},
        data : () => ({
        })
    }
</script>
<style>
</style>