<template>
    <div class="componentWrapper">
        <h1>Samtal</h1>

        <v-tabs class="component__tabs" v-model="tab" background-color="white">
            <v-tab v-for="item in items" :key="item">
                {{ item }}
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item :key="0" class="tabWrapper">
              <TherapyComponent />
            </v-tab-item>
            <v-tab-item :key="1" class="tabWrapper">
              <StressComponent/>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
    import TherapyComponent from './TherapyComponent.vue'
    import StressComponent from './StressComponent.vue'

    export default {
        name : 'ConversationComponent',
        components : {
          TherapyComponent,
          StressComponent
        },
        data : () => ({
            tab: null,
            items: ['Återhämtningsterapi', 'Stresshantering']
        })
    }
</script>
<style>
.therapyImageFlower {
    background-image: url(https://images.unsplash.com/photo-1461766705442-58d58276121a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80) 
}
</style>