<template>
    <v-card class="card" max-width="400" height="430" outlined>
        <v-img
            height="200px"
            :src=imgSrc>
        </v-img>

        <v-card-title>{{title}}</v-card-title>

        <v-card-subtitle class="pb-0">
            {{subTitle}}
        </v-card-subtitle>

        <v-card-text class="text--primary">
            {{text}}
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name : 'Card',
        props: ['title', 'subTitle', 'text', 'imgSrc'],
        data : () => ({})
    }
</script>

<style>
.card {
    margin: 2rem 2rem
}
.card:hover {
    cursor: pointer;
    transition: 0.6s all ease-in-out;
    box-shadow: 0 10px 20px rgba(0,0,0,0.08), 0 6px 6px rgba(0,0,0,0.13);
}
</style>