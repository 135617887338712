<template>
    <div>
        <div class="tabSection">
            <div class="topLeftContainer">
                <div>
                    Det finns många sätt för att lära sig hantera stress, men i grund och botten är stresshantering något annat. Stresshantering handlar främst om att lära känna sig själv. 
                    Det innebär är att ta hand om sig själv, att hitta balansen i livet, att göra prioriteringar såsom sin egen hälsa och sitt eget välmående. 
                </div>
                <div>
                    Under stresshantering hjälper jag till att finna svar på frågor som: 
                    <ul>
                        <li>
                            Vad behöver just jag för att må bra? 
                        </li>
                        <li>
                            Vad behöver jag för att ta hand om min egen hälsa? 
                        </li>
                        <li>
                            Hur kan jag lyssna till mitt eget hjärta? 
                        </li>
                    </ul>
                    <br/>
                    Det är viktigt att förstå sina egna behov, att kunna uttrycka vem man är och att hitta sin egen röst. Att kunna sätta sina egna gränser och att göra de tydliga, både för sig själv och andra är ett sätt att säga JA till sig själv. 
                </div>
            </div>
            <div class="stressImage sectionImageSmall">
            </div>
    </div>
        <div class="tabSection2" style="padding-top: 8rem;">
            <h1 style="margin-bottom: 2rem;">Jag erbjuder</h1>
            <div class="sectionBodyWrapper">
                <div class="threeGridColumnWidth">
                    <h2>Samtal</h2>
                    Du är välkommen på samtal i min lokal i Västra Göteborg.
                    <br/>
                    <br/>
                    Samtal erbjuds även digitalt via Zoom.
                    <br/>
                    <br/>
                    <h3>Kostnad</h3>
                    Nu nya priser utifrån dina möjligheter!
                    <br/>
                    <br/>
                    <b>Samtal (60min):</b> 600kr
                    <br/>
                    <br/>
                    <h3>Bokning</h3>
                    Bokning sker via <a href="mailto:helenasdrivbank@gmail.com">mail</a> eller via <a href="https://helenasdrivbank.bokadirekt.se/" target="_blank">boka direkt</a>
                </div>
                <div class="threeGridColumnWidth">
                    <h2>Verktyg</h2>
                    Vi kommer använda oss av olika redskap såsom mindfulness, medveten närvaro och meditation för att kunna vara här och nu. Eller som Björn Natthiko Lindeblad så fint uttrycker det – att vara Varsevarande. Vi kommer även att använda natur och trädgård som redskap för läkning och tillfrisknande.
                </div>
                <div class="threeGridColumnWidth">
                    <h2>Föreläsningar</h2>
                    Jag föreläser om både stresshantering och återhämtningsterapi. Här finns även möjlighet för er att önska innehåll utifrån era behov.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name : 'StressComponent',
        components : {
        },
        data : () => ({})
    }
</script>

<style>
.stressImage {
    background-image: url(https://images.unsplash.com/photo-1612606572149-1c3db0edcd59?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1283&q=80)
}
</style>