<template>
    <div>
        <div class="tabSection">
            <div class="topLeftContainer">
                    Ibland kan det vara lätt att glömma vem den viktigaste personen är i ens liv.
                    Återhämtningsterapi kan hjälpa dig att hitta tillbaka till huvudpersonen i ditt
                    liv, du själv.
                    <br/>
                    <br/>
                    Livet händer, saker och ting sker som vi inte har kontroll över. Vardagen blir
                    ibland till ett berg av måsten och det kan vara svårt att se hur vi ska orka ta
                    oss över. Att mitt i allt hitta tid för sitt eget mående kan kännas omöjligt.
                    Det är lätt att stressen tar över och vår energi går åt till allt annat och till
                    andra i vår omgivning. Det kan kännas omöjligt att ens se hur vi ska kunna bryta
                    mönstret.
                    <br/>
                    <br/>
                    Våra växter behöver rätt sorts näring och växtförhållanden för att må bra.
                    Precis på samma sätt är det för oss. Även vi människor behöver rätt sorts näring
                    och växtförhållanden för att må bra och utvecklas till vårt sanna jag.
                    <br/>
                    <br/>
                    Genom återhämtningsterapi får du nya sätt att se på din situation. Jag finns med
                    dig med olika redskap så att du kan finna just din sorts näring till ett bättre
                    mående och ett inre lugn.
                    <br/>
                    <br/>
                    Återhämtningsterapi är att se hela människan på ett holistiskt sätt – kropp,
                    själ och sinne i harmoni och balans. Återhämtningsterapi är ett sätt att hämta
                    dig åter. Tillsammans skapar vi plats för något nytt. Att finna ett ”nytt dig”
                    och en ny väg i livet.
                    <br/>
                    <br/>
                    Förståelse för varför vi gör som vi gör är ett sätt till läkning och
                    tillfrisknande. Det är alltid du som klient som är i fokus och tillsammans
                    skapar vi förståelse till orsaken för ditt mående. Att förstå varför är grunden
                    till att kunna göra förändringar och att därefter kunna finna ett nytt sätt att
                    leva i harmoni och balans. Genom återhämtningsterapi får du ett nytt sätt att
                    förhålla dig till livet.
                </div>
                <div class="therapyImageFlower sectionImageSmall">
                </div>
        </div>
        <div
            class="tabSection"
            style="background-color: #edf3eb; font-size: 2rem; text-align: center; margin: 0 auto">
            Nu är det tid för dig att leva inifrån och ut, istället för utifrån och in!
        </div>
        <div class="tabSection2">
            <h1>Jag erbjuder</h1>
            <div class="sectionBodyWrapper">
                <div class="threeGridColumnWidth">
                    <h2>Samtal</h2>
                    Du är välkommen på samtal i min lokal i Västra Göteborg.
                    <br/>
                    <br/>
                    Samtal erbjuds även digitalt via Zoom.
                    <br/>
                    <br/>
                    <h3>Kostnad</h3>
                    <b>Samtal (60min):</b> 600kr
                    <br/>
                    <br/>
                    <h3>Bokning</h3>
                    Bokning sker via <a href="mailto:helenasdrivbank@gmail.com">mail</a> eller via <a href="https://helenasdrivbank.bokadirekt.se/" target="_blank">boka direkt</a>
                </div>
                <div class="threeGridColumnWidth">
                    <h2>Verktyg</h2>
                    Vi kommer använda oss av olika redskap såsom mindfulness, medveten närvaro och
                    meditation för att kunna vara här och nu. Eller som Björn Natthiko Lindeblad så
                    fint uttrycker det - att vara Varsevarande. Vi kommer även att använda natur och
                    trädgård som redskap för läkning och tillfrisknande.
                </div>
                <div class="threeGridColumnWidth">
                    <h2>Föreläsningar</h2>
                    Jag föreläser om både stresshantering och återhämtningsterapi. Här finns även möjlighet för er att önska innehåll utifrån era behov.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name : 'TherapyComponent',
        components : {},
        data : () => ({})
    }
</script>
<style>
.therapyImageFlower {
    background-image: url(https://images.unsplash.com/photo-1461766705442-58d58276121a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80) 
}
</style>